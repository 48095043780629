import React from "react"
import { Typography } from "@material-ui/core"
import Traffic from "../../images/Pace_traffic_benefit.svg"
import Conversion from "../../images/Pace_conversion_benefit.svg"
import Return from "../../images/Pace_return_customer_benefit.svg"

export const benefitsTitle = "The benefits of faster loading websites"

export const benefitsIntro = (
  <>
    Paceology is a website optimization service that addresses a significant
    erosion in website traffic, sales, and overall conversion that is mostly
    overlooked: Website loading performance, especially on mobile networks.
  </>
)

export const benefitsSections = [
  {
    id: "traffic",
    title: "More traffic to your website",
    image: Traffic,
    more: "More about speed and ranking",
    summary:
      "Optimized websites with faster loading time rank better in Google search results.",
    content: (
      <>
        <Typography paragraph>
          One of Google's priorities is to present the best experience to its
          users. This means serving up sites that are properly optimized to load
          quickly, reducing user frustration.
        </Typography>
        <Typography paragraph>
          Slow loading sites typically result in higher bounce rates. Visitors
          attempt to load a page and quickly abandon this attempt to find
          content elsewhere if the wait is too long. This is recognized by the
          Google algorithm as a quality issue if it occurs too often.
        </Typography>
        <Typography paragraph>
          Slow loading time also results in fewer page views and can even affect
          the Google crawler bot (designed to index content to show in Google
          results) by reducing the number of pages it indexes.
        </Typography>
        <Typography paragraph>
          Beyond the above side-effects of slow speed on search results, in July
          2018, Google released a new ranking algorithm designed for mobile
          search called "Speed Update". This means that now both desktop and
          mobile speed are direct factors in ranking criteria.
        </Typography>
        <Typography paragraph>
          Google is not only explicit about the impact of speed on search
          results but openly promotes these standards. In fact, this website
          (paceology.com) is connected to the Google Page Speed Insights API,
          providing the data that we display as speed test results. Google
          recommends this exact test with a focus on mobile networks to measure
          your site for improvements.
        </Typography>
        <Typography paragraph>
          This means that if you are not scoring well on the test we provide at
          the top of this website, chances are you have some work to do if you
          want Google to regard your website as a pleasant experience to present
          to its billions of users.
        </Typography>
        <Typography paragraph>
          This seems to now be confirmed by a 2019 Searchmetrics study showing
          that the top ranking sites load faster and provide a smoother user
          experience on mobile.
        </Typography>
        <Typography paragraph>
          The report shows that the "First Meaningful Paint" (the time that a
          site appears loaded) has a 1.19 second average on page one of Google
          search results and a 1.29 second average on page two of Google search
          results. Also, 80% of results on page one had a "Time to First Byte"
          below 0.6 seconds, which Google suggests is necessary for an
          acceptable user experience.
        </Typography>
        <Typography paragraph>
          According to Daniel Furch, Director of Marketing EMEA at
          Searchmetrics:
        </Typography>
        <Typography paragraph>
          “For anyone that has not already optimized their site speed, things
          are likely to get tougher.”
        </Typography>
        <Typography paragraph>
          “The more your competitors speed up their sites, the more you’ll stand
          out negatively if your site is slow. And as people become accustomed
          to near-instant loading, any slow pages will put them off. You can
          expect speed to grow in importance and pages to get faster overall.”
        </Typography>
      </>
    ),
  },
  {
    id: "conversion",
    title: "Convert more traffic to sales",
    image: Conversion,
    more: "More about load time and conversion",
    summary:
      "It's well documented that Faster loading pages increase sales conversion ",
    content: (
      <>
        <Typography paragraph>
          As we already present as the main headline on our website, Google
          claims that if your site loads slower than 3 seconds, you will lose
          over half of your mobile traffic.
        </Typography>
        <Typography paragraph>
          Of course, this loss of traffic also means you have fewer
          opportunities for sales, dropping your overall conversion.
        </Typography>
        <Typography paragraph>
          There have been several major businesses, organizations, news sites
          (including the BBC), and social media sites (like Instagram) who have
          reported significant gains in sales conversions by changes that
          decrease load time by a second or less.
        </Typography>
      </>
    ),
  },
  {
    id: "return",
    title: "Increase returning visitors",
    image: Return,
    more: "More about customer loyalty",
    summary:
      "Studies show that speed is a metric in brand loyalty. Slow pages discourage new visitors who won't return.",
    content: (
      <>
        <Typography paragraph>
          Websites that load slowly result in poor customer satisfaction. A
          1-second delay decreases customer satisfaction by 16%.
        </Typography>
        <Typography paragraph>
          Not only will you lose a significant amount of return customers, but
          these customers also complain to their friends. 44% share their
          negative impressions online.
        </Typography>
      </>
    ),
  },
]
