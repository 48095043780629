import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Back from "../components/UI/Back"
import { Container, Typography, makeStyles } from "@material-ui/core"
import {
  benefitsTitle,
  benefitsIntro,
  benefitsSections,
} from "../components/page-info/benefits-info"
import PaperFloatedImage from "../components/utils/PaperFloatedImage"

const useStyles = makeStyles(theme => ({
  section: {
    marginTop: theme.spacing(4),
  },
}))

const Benefits = props => {
  const classes = useStyles(props)

  return (
    <Layout titleId="benefits-title">
      <Back />
      <SEO title="Benefits" />
      <Container maxWidth="sm">
        <Typography id="benefits-title" component="h2" variant="h4" gutterBottom>
          {benefitsTitle}
        </Typography>
        <Typography gutterBottom>{benefitsIntro}</Typography>

        {benefitsSections.map(({ id, title, image, content }, idx) => (
          <section className={classes.section} id={id}>
            <Typography component="h3" variant="h5" gutterBottom>
              {title}
            </Typography>
            <PaperFloatedImage src={image} />
            <Typography gutterBottom>{content}</Typography>
          </section>
        ))}
      </Container>
    </Layout>
  )
}

export default Benefits
