import React from "react"
import { makeStyles, Typography } from "@material-ui/core"
import BackIcon from "@material-ui/icons/KeyboardArrowLeft"
import { Link } from "gatsby"

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    padding: 15,
    height: 70,
    borderBottom: "1px solid lightgrey",
    marginBottom: 20,
    position: "-webkit-sticky",
    position: "sticky",
    top: 0,
    background: "#fafafa",
  },
  back: {
    textDecoration: "none",
    marginTop: 1,
  },
}))

const Back = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div>
        {" "}
        <Link to="/">
          <BackIcon style={{ fontSize: 34, opacity: 0.8 }} />
        </Link>
      </div>
      <div>
        <Link className={classes.back} to="/">
          <Typography variant="h6" className={classes.back}>
            {" "}
            Back{" "}
          </Typography>{" "}
        </Link>
      </div>
    </div>
  )
}
export default Back
