/* eslint-disable jsx-a11y/alt-text */
import React from "react"
import { makeStyles, Paper } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  root: {
    display: "inline-block",
    padding: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up("sm")]: {
      display: "block",
      float: "right",
      marginLeft: theme.spacing(1),
    },
    "& img": {
      display: "block",
      borderRadius: 2,
    },
  },
}))

/**
 * An image style used on
 * - 140px high decorative svg image
 * - Lazy loaded
 * - Floated to the right and surrounded in a paper box
 */
const PaperFloatedImage = props => {
  const classes = useStyles(props)
  const {
    src,
    height = 140,
    width,
    loading = "lazy",
    alt = "",
    imgProps: imgPropsProp,
    elevation = 1,
    role: roleProp,
    PaperProps: PaperPropsProp,
  } = props

  const PaperProps = {
    elevation,
    ...PaperPropsProp,
  }

  const imgProps = {
    src,
    height,
    width,
    loading,
    alt,
    role: roleProp === undefined && !alt ? "presentation" : roleProp,
    ...imgPropsProp,
  }

  return (
    <Paper {...PaperProps} classes={classes}>
      <img {...imgProps} />
    </Paper>
  )
}

export default PaperFloatedImage
